import * as React from 'react';
import { graphql } from 'gatsby';
import { styled } from 'twin.macro';
import { useI18next } from 'gatsby-plugin-react-i18next';

import Layout from '../components/Layout';
import { Container, Section } from '../components/Grid';
import PageHero from '../components/blog/PageHero'
import ArticleItem from '../components/blog/ArticleItem'


const Heading = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 40px;
  line-height: 127%;
  color: #224B45;
`;

const FlexRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`


const BlogIndex = ({ data, location }) => {
  const { language, t } = useI18next();
  const posts = data.allMdx.nodes;
  const filteredPosts = posts.filter(post => {
    const postLang = post.frontmatter.language || 'en';
    return postLang === language;
  });

  return (
    <Layout>
      <PageHero title="blog_title" subline="blog_subline" />
      <Container>
        <Section>
          <Heading>{t('blog_articles_title')}: {filteredPosts.length}</Heading>
          <FlexRow>
            {filteredPosts.map(post => {
              const title = post.frontmatter.title || post.frontmatter.slug;

              return (
                <ArticleItem key={title} post={post} />
              );
            })}
          </FlexRow>
        </Section>
      </Container>
    </Layout >
  );
};

export default BlogIndex;

export const Head = () => <></>;

export const pageQuery = graphql`
  query ($language: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: {fields: [frontmatter___date], order: ASC}
    ) {
      nodes {
        excerpt
        frontmatter {
          date
          title
          description
          slug
          language
          tags
          coverImg {
            childImageSharp {
              gatsbyImageData
            }
          }
         
        }
      }
    }
    locales: allLocale(
      filter: {language: {eq: $language}, ns: {in: ["common", "not_found"]}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
