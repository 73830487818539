const metaTagsCZ = {
    "/": {
        title: "Úklidová firma Praha – profesionální úklid domácností",
        description: "Jsme vaše úklidová firma pro Prahu ✔️ Profesionální úklid domácností i kanceláří, společných prostor, mytí oken ✔️ Přívětivý ceník. Děláme od 8 do 8.",
        keywords: [
            "úklidová firma",
            "úklidová firma praha",
            "úklidová firma ceník",
            "profesionální úklid domácnosti",
            "uklízecí firma praha"
        ],
    },
    "/cenik-uklidovych-praci": {
        title: "Paní na úklid – orientační cena úklidu dle ceníku roku 2022",
        description: "Kolik stojí paní na úklid - cena úklidu za m2, hodinová sazba za mytí oken či cena za generální úklid bytu ✔️ Vše prozradí náš ceník úklidových prací",
        keywords: [
            "paní na úklid cena",
            "ceník úklidu 2022",
            "mytí oken hodinová sazba",
            "hodinová sazba za úklid",
            "cena úklidu za m2",
            "kalkulace úklidových prací",
            "generální úklid bytu cena",
            "cena úklidových prací na hodinu",
            "ceník úklidových prací",
            "úklid po malování cena",
            "úklidové práce ceník",
            "ceník úklidových prací praha",
            "cena za úklid bytu",
            "ceník úklidové práce",
            "úklid domácností praha cena",
        ],
    },
    "/cisteni-calouneni-a-kobercu": {
        title: "Čištění koberců, sedaček i čalounění Praha - budou jako nové",
        description: "Na profesionální čištění koberců, běžných i kožených sedaček a čalounění je naše firma v Praze machr ✔️ Hloubkové čištění zbaví všech nečistot ✔️.",
        keywords: [
            "čištění koberců",
            "čištění sedaček",
            "čištění koberců praha",
            "čištění čalounění",
            "čištění sedaček praha",
            "čištění kožených sedaček",
            "hloubkové čištění sedaček",
            "profesionální čištění sedaček",
            "čištění koberců praha 5",
            "čištění koberců praha 9",
            "suché čištění koberců",
            "čištění koberců praha 10",
            "čištění koberců praha 4",
            "čištění koberců praha 6",
            "mokré čištění koberců",
            "čištění perských koberců praha",
            "profesionální čištění koberců",
            "suché čištění sedaček",
            "čištění perských koberců",
            "čištění sedaček cena",
            "ruční čištění koberců",
            "čištění koberců a sedaček",
            "čištění koberců a čalounění",
            "čištění koberců cena za metr",
            "čištění koberců ceník",
            "čištění čalounění pohovky",
            "čištění čalounění praha",
            "firma na čištění sedaček",
        ],
    },
    "/darkove-poukazky": {
        title: "Dárkové poukazy - darujte blízkým úklid od profesionálů",
        description: "Naše dárkové poukazy šetří obdarovaným čas a starosti s úklidem ✔️ Využít jdou na jednorázový úklid, mytí oken, žehlení a další služby z naší nabídky.",
        keywords: [],
    },
    "/informace-o-zpracovani-osobnich-udaju": {
        title: "Informace o zpracování osobních údajů - jak s nimi nakládáme",
        description: "Vaše osobní údaje zpracováváme v souladu s GDPR ✔️ Zde se dozvíte více o tom, jaké údaje shromažďujeme, proč a k čemu je používáme ✔️",
        keywords: [],
    },
    "/myti-oken": {
        title: "Mytí oken a výloh Praha - jsme vaše firma na mytí oken",
        description: "Děláme profesionální mytí oken a výloh všech typů po celé Praze ✔️ Střešní, francouzská, dvojitá, okna v paneláku i rodinném domě ✔️ Přívětivý ceník.",
        keywords: [
            "mytí oken",
            "mytí oken cena",
            "mytí oken praha",
            "ceník mytí oken",
            "cenová nabídka mytí oken",
            "mytí oken ceník",
            "cena mytí oken",
            "mytí oken v paneláku",
            "cena za mytí oken",
            "firma na mytí oken",
            "mytí střešních oken",
            "mytí oken rodinný dům",
            "jak na mytí oken",
            "profesionální mytí oken",
        ],
    },
    "/souhlas-se-zpracovanim-souboru-cookies": {
        title: "Souhlas se zpracováním souborů cookies - a jak je používáme",
        description: "Náš web používá soubory cookies ⭐ Zjistěte více o tom, co jsou zač, jaké druhy a jak je využíváme ⭐ Váš souhlas s používáním souborů cookies.",
        keywords: [],
    },
    "/uklid-domacnosti": {
        title: "Úklid domácností, bytů i domů Praha - generální i pravidelný",
        description: "Profesionální úklid domácností, bytů a domů v Praze - generální, pravidelný i jednorázový ✔️ Zkušené paní na úklid a příznivá cena - nabídka zdarma.",
        keywords: [
            "úklid domácnosti",
            "úklid bytu",
            "úklid domácnosti praha",
            "úklid domu",
            "úklid domácnosti cena",
            "pravidelný úklid domácnosti",
            "úklid domácnosti ceník",
            "generální úklid domácnosti",
            "úklid v domácnosti",
            "paní na úklid domácnosti",
            "profesionální úklid domácnosti",
            "úklid domácnosti praha východ",
            "jednorázový úklid domácnosti praha",
        ],
    },
    "/uklid-kancelari": {
        title: "Večerní i ranní úklid kanceláří a firem v Praze - i víkendy",
        description: "Náš tým se nebojí ranního ani večerního úklidu kanceláře či firmy, po celé Praze ⭐ Pracujeme mimo pracovní dobu i o víkendech bez příplatků ✔️",
        keywords: [
            "úklid kanceláří",
            "hledám paní na úklid kanceláří praha",
            "večerní úklid kanceláří praha",
            "úklid kanceláří praha",
            "ranní úklid kanceláří praha",
            "úklid firem",
            "úklid firem praha",
        ],
    },
    "/uklid-po-malovani-a-rekonstrukci": {
        title: "Úklid po malování, rekonstrukci i stavbě v Praze - zařídíme",
        description: "Děláme kompletní úklid po malování, stavbě či rekonstrukci v Praze ⭐ Přiznivé ceny, pracujeme 7 dní v týdnu. Vypracování konkrétní nabídky zdarma ⭐.",
        keywords: [
            "úklid po malování",
            "úklid po rekonstrukci",
            "úklid po malování cena",
            "úklid bytu po rekonstrukci",
            "úklid bytu po rekonstrukci cena",
            "úklid po rekonstrukci praha",
            "cena za úklid bytu po rekonstrukci",
            "cena za úklid po malování",
            "úklid bytu po malování",
            "úklid domácnosti po rekonstrukci",
            "úklid po malování praha",
            "úklid po rekonstrukci cena",
        ],
    },
    "/uklid-spolecnych-prostor": {
        title: "Úklid společných prostor bytových domů v Praze - kdykoliv",
        description: "Úklid společných prostor bytových domů přenechte profesionálům ⭐ Jsme k dispozici 7 dní v týdnu po celé Praze ⭐ Příznivá cena podle rozsahu práce.",
        keywords: [
            "úklid společných prostor",
            "úklid společných prostor bytových domů",
            "úklid společných prostor v bytovém domě",
            "úklid společných prostor cena",
            "úklid společných prostor praha",
            "úklid společných prostor domu cena",
        ],
    },
    "/vseobecne-obchodni-podminky": {
        title: "Všeobecné obchodní podmínky - rámec našich služeb",
        description: "Seznamte se s našimi všeobecnými obchodními podmínkami. Vymezení našich služeb, odpovědnosti, povinnosti a právní rámec naší smlouvy najdete zde.",
        keywords: [],
    }
}

const metaTagsEN = {
    "/en": {
        title: "Cleaning Services In Prague - Home & Office Cleaning & More",
        description: "Choose professional cleaning services in Prague ✔️ Home, office common areas - we take care of all things cleaning. Fair prices, simple booking.",
        keywords: [],
    },
    "/en/cleaning-of-common-areas": {
        title: "Residential Common Areas Cleaning in Prague - We Get It Done",
        description: "Shared spaces & common areas cleaning is our forte ✔️ Leave it to professionals who don't mind working the weekeds & flexible hours ⭐ Easy booking.",
        keywords: [],
    },
    "/en/consent-to-the-processing-of-cookies": {
        title: "Cookie Consent - & How We Use Cookies On Our Web",
        description: "Our website uses cookies ⭐ Find out more about what cookies are, what kinds and how we use them ⭐ Law & your consent with our cookie use.",
        keywords: [],
    },
    "/en/gift-vouchers": {
        title: "Order a Cleaning Gift Card - & Gift Someone A Shiny Home",
        description: "Our cleaning gift cards save time & worries to those you gift them to ⭐ Usable for one-time cleaning, window cleaning, ironing & more of our services.",
        keywords: [],
    },
    "/en/home-cleaning": {
        title: "House & Home Cleaning Services in Prague - 7 Days a Week",
        description: "Our house & home cleaning services save your time & nerves ⭐ General & regular cleaning of apartments, houses & buildings, available 7 days a week ✔️.",
        keywords: [],
    },
    "/en/information-on-the-processing-of-personal-data": {
        title: "Processing of Your Personal Data - How We Use Them",
        description: "We process your personal data according to GDPR ✔️ Here you can learn more about what data do we collect, why and what do we use them for ✔️.",
        keywords: [],
    },
    "/en/office-cleaning": {
        title: "Office & Commercial Building Cleaning - A Cleaner Workspace",
        description: "Office & commercial building cleaning any day or time ✔️ Comprehensive, regular or one-time. We clean outside the working hours w/ no extra charge ⭐.",
        keywords: [],
    },
    "/en/post-construction-cleaning": {
        title: "Move-out & Post-construction Cleaning Services - We Got It",
        description: "Just leave move-out & post-construction cleaning to us ✔️ We do a complete cleaning after moving, painting or refurbishing a flat, house or office ⭐.",
        keywords: [],
    },
    "/en/prices-of-cleaning-services": {
        title: "Price List of Our Cleaning Services - How Much Will It Cost",
        description: "Some rough prices for one-off, regular or other cleaning ✔️ Exact prices are calculated according to the needs of the client. Get a free quote!",
        keywords: [],
    },
    "/en/terms-and-conditions-of-service": {
        title: "Terms and Conditions - Get Acquainted With Them",
        description: "Here are our Terms and Conditions relating to the provision of our services ✔️ Yours and our rights, contract withdrawal, liabilities & more info.",
        keywords: [],
    },
    "/en/upholstery-and-carpet-cleaning": {
        title: "Carpet, Sofa & Upholstery Cleaning in Prague - From The Pros",
        description: "Thorough carpet, sofa & upholstery cleaning in Prague is where we shine ✔️ Removing stains, odors, bacteria & allergens, restoring color & more ✔️.",
        keywords: [],
    },
    "/en/window-washing": {
        title: "Window Cleaning Services - Window Washing In Prague",
        description: "Our window cleaning services in Prague can handle it all - single, double & french pane, roof, skylights, glass panel ✔️ Get a free price quote!",
        keywords: [],
    }
}

const getMetaData = (language = "cs", path: string) => {
    const currentMeta = language === "cs" ? metaTagsCZ : metaTagsEN
    return currentMeta[path]
}

export default getMetaData