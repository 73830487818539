import React, {useState} from 'react';

import data from '../../../config/site_data.json';
import {v4 as uuidv4} from 'uuid';
import tw, {styled} from 'twin.macro';
import {Container, Section} from '../../Grid';
import {useTranslation} from 'react-i18next';
import UpholsteryList from './UpholsteryList';

const StyledSection = styled(Section)`
  margin-bottom: 95px;

  @media ${props => props.theme.screens.md} {
    margin-bottom: 0;
  }
`;

const Title = styled.h2<{selected?: boolean}>`
  ${tw`text-secondary`}

  font-size: 27px;
  line-height: 122.5%;
  margin-bottom: 24px;

  @media ${props => props.theme.screens.md} {
    ${tw`text-200`}
    margin-bottom: 33px;
    color: ${props => (props.selected ? props.theme.colors.secondary : '')};
  }

  @media ${props => props.theme.screens.lg} {
    &:nth-of-type(2) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
`;

const StyledTable = styled.table`
  border-collapse: collapse;
  border-radius: 17px;
  border-style: hidden;
  box-shadow: 0 0 0 2px #e8eae9;
  margin-bottom: 55px;
  width: 100%;

  th {
    ${tw`font-medium text-200 text-left`}
    font-size: 17px;
    line-height: 122.5%;

    @media ${props => props.theme.screens.md} {
      font-size: 24px;
      line-height: 122.5%;
    }
  }

  td {
    ${tw`text-secondary `}

    font-size: 19px;
    line-height: 140%;
    @media ${props => props.theme.screens.md} {
      font-size: 25px;
      line-height: 122.5%;
    }
  }

  th,
  td {
    border: 2px solid #e8eae9;
    padding: 12px;

    @media ${props => props.theme.screens.md} {
      padding: 37px 70px;
    }
  }
`;

const MobileWrapper = styled.div`
  ${tw`md:hidden`}
`;

const Wrapper = styled.div`
  ${tw`hidden md:block`}
`;

const Headings = styled.ul`
  ${tw`flex justify-between`}

  ${Title} {
    ${tw`cursor-pointer hover:opacity-80 transition-opacity transition-colors`}
  }

  max-width: 956px;

  @media ${props => props.theme.screens.lg} {
    max-width: 100%;
  }
  @media ${props => props.theme.screens.xl} {
    max-width: 1300px;
  }
`;

const NotesHeading = styled.h6`
  ${tw`text-secondary mb-4 text-lg`}
`;

const Notes = styled.ul`
  ${tw`text-secondary`}
  list-style: disc;
  padding-left: 20px;
  margin-bottom: 40px;
  @media ${props => props.theme.screens.md} {
    margin-bottom: 0px;
  }
`;

const Td = styled.td`
  a {
    color: #039259;
    text-decoration: underline;
  }
`

const Table = ({data}: any) => {
  const {t} = useTranslation();
  return (
    <StyledTable>
      <thead>
        <tr>
          {data.headings.map((item: any) => (
            <th key={uuidv4()}>{t(item)}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.rows.map((items: any) => (
          <tr key={uuidv4()}>
            {items.map((item: any) => (
              <Td dangerouslySetInnerHTML={{ __html: t(item)}} key={uuidv4()} />
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

const Menu = () => {
  const {t} = useTranslation();

  const [selected, setSelected] = useState('one-off');

  const handleSelected = (e: any) => {
    setSelected(e.target.id);
  };
  
  return (
    <StyledSection>
      <Container>
        <MobileWrapper>
          <Title>{t(data.prices.home_cleaning.title)}</Title>
          <Table data={data.prices.home_cleaning} />
          <NotesHeading>
            {t(data.prices.home_cleaning.notes.heading)}
          </NotesHeading>
          <Notes>
            {data.prices.home_cleaning.notes.notes.map((note: any) => (
              <li key={note}> {t(note)} </li>
            ))}
          </Notes>
          <Title>{t(data.prices.regular_home_cleaning.title)}</Title>
          <Table data={data.prices.regular_home_cleaning} />
          <NotesHeading>
            {t(data.prices.regular_home_cleaning.notes.heading)}
          </NotesHeading>
          <Notes>
            {data.prices.regular_home_cleaning.notes.notes.map((note: any) => (
              <li key={note}> {t(note)} </li>
            ))}
          </Notes>
          <Title>{t(data.prices.upholstery.title)}</Title>
          <UpholsteryList />
        </MobileWrapper>
        <Wrapper>
          <Headings>
            <Title
              id="one-off"
              selected={selected === 'one-off'}
              onClick={handleSelected}
            >
              {t(data.prices.home_cleaning.title)}
            </Title>
            <Title
              id="regular"
              selected={selected === 'regular'}
              onClick={handleSelected}
            >
              {t(data.prices.regular_home_cleaning.title)}
            </Title>
            <Title
              id="upholstery"
              selected={selected === 'upholstery'}
              onClick={handleSelected}
            >
              {t(data.prices.upholstery.title)}
            </Title>
          </Headings>
          {selected === 'one-off' && (
            <>
              <Table data={data.prices.home_cleaning} />

              <NotesHeading>
                {t(data.prices.home_cleaning.notes.heading)}
              </NotesHeading>
              <Notes>
                {data.prices.home_cleaning.notes.notes.map((note: any) => (
                  <li key={note}> {t(note)} </li> 
                ))}
              </Notes>
            </>
          )}

          {selected === 'regular' && (
            <>
              <Table data={data.prices.regular_home_cleaning} />
              <NotesHeading>
                {t(data.prices.regular_home_cleaning.notes.heading)} 123
              </NotesHeading>
              <Notes>
                {data.prices.regular_home_cleaning.notes.notes.map(
                  (note: any) => (
                    <li key={note}> {t(note)} </li>
                  ),
                )}
              </Notes>
            </>
          )}
          {selected === 'upholstery' && <UpholsteryList />}
        </Wrapper>
      </Container>
    </StyledSection>
  );
};

export default Menu;
