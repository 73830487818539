import tw, {styled} from 'twin.macro';

export const Heading = styled.h2`
  ${tw`md:text-heading md:leading-heading text-primary`}

  font-size: 32px;
  line-height: 127%;
`;

export const Text = styled.p`
  ${tw`md:text-paragraph md:leading-paragraph text-200 font-medium font-medium md:font-bold `}

  font-size: 15px;
  line-height: 24px;
`;
