import React from 'react';
import Helmet from 'react-helmet';
import {useLocation} from '@reach/router';
import {useStaticQuery, graphql} from 'gatsby';

import getMetaData from '../utils/meta';

type SEOProps = {
  description?: string;
  lang?: string;
  meta?: any;
  keywords?: any;
  title: string;
  noIndex?: boolean;
};

const SEO: React.FunctionComponent<SEOProps> = ({
  description,
  lang,
  meta,
  keywords,
  title,
  noIndex,
}) => {
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );
  const location = useLocation();
  const isNoIndex = location.origin === 'https://develop.magnolia-uklid.cz';
  console.log(isNoIndex, location.origin);
  const metaDescription = description || site.siteMetadata.description;
  const metaRobots =
    isNoIndex || noIndex ? {name: 'robots', content: 'noindex'} : {};
  let pathname = '';
  if (typeof window !== 'undefined') {
    pathname = window?.location?.pathname;
  }
  const metaData = getMetaData(lang, pathname);

  const currentKeywords = metaData?.keywords ?? keywords;

  const schema = {
    '@context': 'https://schema.org/',
    '@type': 'CreativeWorkSeries',
    name: 'Magnolia-uklid.cz',
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: '4.9',
      bestRating: '5',
      ratingCount: '273',
    },
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaData?.title ?? title}
      // titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        metaRobots,
        {
          name: `description`,
          content: metaData?.description ?? metaDescription,
        },
        {
          property: `og:title`,
          content: metaData?.title ?? title,
        },
        {
          property: `og:description`,
          content: metaData?.description ?? metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaData?.title ?? title,
        },
        {
          name: `twitter:description`,
          content: metaData?.description ?? metaDescription,
        },
        {
          name: 'google-site-verification',
          content: '2w1DvTJiGhP5l_J7k4FDaAftvY_DiUjtsfEL8xJYPrk',
        },
      ]
        .concat(
          currentKeywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : [],
        )
        .concat(meta)}
    >
      {typeof window !== 'undefined' && (
        <link rel="canonical" href={window?.location?.href} />
      )}
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
};

export default SEO;
