import React from 'react';
import {graphql} from 'gatsby';

import Layout from '../components/Layout';

import {useI18next} from 'gatsby-plugin-react-i18next';
import SEO from '../components/Seo';
import Banner from '../components/Page/prices/Banner';
import {Container} from '../components/Grid';
import ContactFormPage from '../components/Page/ContactFormPage';
import {FaqFormWrapper} from './window-washing';
import Menu from '../components/Page/prices/Menu';

const Prices = () => {
  const {language, t} = useI18next();

  return (
    <Layout headerStyle="windowWashing">
      <SEO
        title={t('prices_list_seo_title')}
        description={t('prices_list_seo_title_description')}
        keywords={t('prices_list_seo_keywords', {
          returnObjects: true,
        })}
        lang={language}
      />
      <Banner />
      <Menu />
      <Container>
        <FaqFormWrapper>
          <ContactFormPage />
        </FaqFormWrapper>
      </Container>
    </Layout>
  );
};

export default Prices;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: {eq: $language}
        ns: {in: ["common", "prices", "seo_prices"]}
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
