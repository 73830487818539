import React, {forwardRef, useImperativeHandle, useState} from 'react';
import tw, {styled} from 'twin.macro';
import {Button} from '../../../Button';
import SVGIcon from '../../../SVGIcon';

const SliderHeader = styled.div`
  ${tw`flex  justify-between items-center mb-8 mx-auto lg:hidden `}

  max-width: 486px;

  div {
    span {
      ${tw`block text-center`}
    }
  }
`;

const Name = styled.span`
  ${tw`text-secondary`}
  font-size: 22px;
  line-height: 132.5%;
`;

const Position = styled.span`
  ${tw`text-200 font-medium`}
  font-size: 17px;
  line-height: 32px;
`;

const SliderButton = styled(Button)`
  ${tw`text-secondary rounded-full p-0`}
  width: 37px;
  height: 37px;

  svg,
  span {
    ${tw`pointer-events-none`}
  }

  &:first-child {
    transform: rotate(180deg);
  }
`;

const HeaderSlider: React.FC<{
  items: [any];
  sliderRef: any;
  handleSliderButton: Function;
}> = forwardRef((props, ref) => {
  const [index, setIndex] = useState(0);

  useImperativeHandle(ref, () => ({
    changeIndex() {
      if (props.sliderRef.current) {
        setIndex(props.sliderRef.current.index);
      }
    },
  }));

  const item = props.items[index];

  return (
    <SliderHeader {...props}>
      <SliderButton
        data-slide-direction="<"
        color="outline-secondary"
        onClick={props.handleSliderButton}
        aria-label="drawer close"
      >
        <SVGIcon icon="drawer_close" />
      </SliderButton>
      <div>
        <Name>{item.name}</Name>
        <Position>
          {item.position && item.company
            ? `${item.position}, ${item.company}`
            : ''}
        </Position>
      </div>
      <SliderButton
        data-slide-direction=">"
        color="outline-secondary"
        onClick={props.handleSliderButton}
        aria-label="drawer close"
      >
        <SVGIcon icon="drawer_close" />
      </SliderButton>
    </SliderHeader>
  );
});

export default HeaderSlider;
