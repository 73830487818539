import React from 'react';
import tw, { styled } from 'twin.macro';
import data from '../../../config/site_data.json';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Container, Section } from '../../Grid';
import { Heading } from '../../Typography';
import SVGIcon from '../../SVGIcon';
import { StyledButton, Description } from './RegularCleaning';
import BookNowController from '../../BookNowController';
import { List as BulletList } from '../office-cleaning/Included';
const Wrapper = styled(Section)``;

const StyledHeading = styled(Heading)`
  ${tw`text-secondary lg:mb-24`}
  font-size: 24px;
  color: #224b45;
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom} !important;

  @media ${props => props.theme.screens.md} {
    font-size: 30px;
  }
`;

const ListWrapper = styled.div`
  ${tw`mt-6 lg:flex flex-wrap`}
`;

const List = styled.div`
  ${tw`border border-100 text-secondary`}
  padding: 10px 20px;
  border-radius: 17px;

  &:not(:last-child) {
    ${tw`mb-4 lg:mb-0 lg:mr-5`}
  }

  @media ${props => props.theme.screens.lg} {
    &:nth-child(1) {
      min-width: 350px;
      margin-bottom: 16px;
    }
    &:nth-child(2) {
      min-width: 300px;
      margin-bottom: 16px;
    }
  }

  @media ${props => props.theme.screens.xl} {
    padding: 28px 44px;
  }
`;

const LisTitle = styled.h4`
  ${tw`font-medium mb-4 lg:mb-8`}

  font-size: 16px;
  line-height: 32px;

  @media ${props => props.theme.screens.lg} {
    font-size: 22px;
    line-height: 32px;
  }
`;

const ItemsWrapper = styled.div`
  ${tw`grid grid-cols-2 gap-y-4 lg:gap-x-4 xl:gap-x-8`}
`;

const Item = styled.p`
  ${tw`flex items-center font-medium`}

  font-size: 15px;
  line-height: 24px;

  @media ${props => props.theme.screens.lg} {
    font-size: 18px;
    line-height: 32px;
  }
`;

const Icon = styled.picture`
  ${tw`text-primary mr-4`}
`;

const LastWrapper = styled.div`
  ${tw`flex items-center lg:flex-col`}
  div {
    ${tw`lg:w-full`}

    &:first-child {
      ${tw`mr-4 mb-0 lg:mr-0 lg:mb-5`}
    }

    h4 {
      margin-bottom: 0;
    }
  }
`;

const StyledBlock = styled.div`
  padding: 35px;

  @media ${props => props.theme.screens.md} {
    margin-bottom: 100px;
  }
  @media ${props => props.theme.screens.lg} {
    padding: 100px;
    margin-bottom: 0px;
  }
`
const DeepCleaning = () => {
  const { t } = useTranslation();

  const createList = (content: string) => content.split(';');

  return (
    <Wrapper>
      <Container>
        <StyledHeading marginTop="80px" marginBottom="30px">
          {t('deep_cleaning_space_heading')}
        </StyledHeading>
        <Description
          dangerouslySetInnerHTML={{ __html: t('deep_cleaning_space_text') }}
        />
        <Description marginTop="30px" fontWeight="bold">
          {t('deep_cleaning_space_type')}
        </Description>
        <BulletList marginBottom="70px">
          {createList(t(`deep_cleaning_space_list`)).map((item: any) => (
            <li key={item} dangerouslySetInnerHTML={{ __html: item }} />
          ))}
        </BulletList>
        <BookNowController>
          <StyledButton>{t('deep_cleaning_space_button')}</StyledButton>
        </BookNowController>
        <StyledBlock
          style={{ marginTop: 100 }}
          className="bg-gray-100 w-full h-full flex flex-col justify-center items-center rounded-lg text-center"
        >
          <StyledHeading marginBottom="20px">
            {t('price_list_home_heading')}
          </StyledHeading>
          <Description
            dangerouslySetInnerHTML={{ __html: t('price_list_home_text') }}
          />
        </StyledBlock>
      </Container>
    </Wrapper>
  );
};

export default DeepCleaning;
