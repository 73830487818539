import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import tw, {styled} from 'twin.macro';
import data from '../../../config/site_data.json';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {Button} from '../../Button';
import {Container} from '../../Grid';
import BookNowController from '../../BookNowController';
import {GatsbyBackgroundPageHero} from '../../../shared/GatsbyBackgroundImage';

const Wrapper = styled.section`
  ${tw`relative pt-14 lg:flex items-center lg:pt-0`}

  background-color: #f4f7f6;

  padding-top: ${props => props.theme.headerHeight};

  @media ${props => props.theme.screens.md} {
    padding-top: 150px;
  }

  @media ${props => props.theme.screens.lg} {
    height: 696px;
    background-color: transparent;
  }
`;
const Content = styled.div`
  ${tw`text-secondary text-center lg:text-left`}

  max-width: 500px;
  margin: 0 auto;
  margin-top: 50px;

  @media ${props => props.theme.screens.md} {
    margin-top: 0;
  }

  @media ${props => props.theme.screens.lg} {
    margin: 0;
  }
`;

const Heading = styled.h1`
  ${tw` md:mb-8`}

  font-size: 37px;
  line-height: 127%;
  @media ${props => props.theme.screens.md} {
    font-size: 49px;
    line-height: 127%;
  }
`;

const Text = styled.p`
  ${tw`mt-4 mb-6`}

  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  @media ${props => props.theme.screens.md} {
    font-size: 21px;
    line-height: 32px;
  }
`;

const StyledButton = styled(Button)`
  ${tw`w-full lg:w-max`}
  height: 52px;
  font-size: 18px;
  line-height: 139.02%;
  letter-spacing: 0.01em;
`;

const PageHero = () => {
  const {t} = useTranslation();

  const {heading, text, button_text} = data.upholstery.hero;

  const {background} = useStaticQuery(graphql`
    query {
      background: file(relativePath: {eq: "upholstery-hero.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            placeholder: NONE
            formats: [AUTO, WEBP, AVIF]
            quality: 90
          )
        }
      }
    }
  `);

  const ContentComponent = () => (
    <Container>
      <Content>
        <Heading>{t(heading)}</Heading>
        <Text>{t(text)}</Text>
        <BookNowController>
          <StyledButton>{t(button_text)}</StyledButton>
        </BookNowController>
      </Content>
    </Container>
  );
  return (
    <GatsbyBackgroundPageHero image={background} wrapperComponent={Wrapper}>
      <ContentComponent />
    </GatsbyBackgroundPageHero>
  );
};

export default PageHero;
