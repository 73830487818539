import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import tw, { styled } from 'twin.macro';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Container } from '../Grid';
import { GatsbyBackgroundPageHero } from '../../shared/GatsbyBackgroundImage';

const Content = styled.div`
  ${tw`text-secondary text-center lg:text-left`}

  max-width: 500px;
  margin: 0 auto;
  margin-top: 50px;

  @media ${props => props.theme.screens.md} {
    margin-top: 0;
  }

  @media ${props => props.theme.screens.lg} {
    margin: 0;
  }
`;

const Heading = styled.h1`
  ${tw` md:mb-8`}

  font-size: 37px;
  line-height: 127%;
  @media ${props => props.theme.screens.md} {
    font-size: 49px;
    line-height: 127%;
  }
`;

const Text = styled.p`
  ${tw`mt-4 mb-6`}

  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  @media ${props => props.theme.screens.md} {
    font-size: 21px;
    line-height: 32px;
  }
`;

const Tag = styled.p`
  padding: 6px 10px;
  border-radius: 14px;
  border: 1px solid rgba(93, 143, 82, 0.5);
  margin-right: 10px;
`

const DateSpan = styled.p`
font-weight: 700;
font-size: 20px;
line-height: 127%;
color: #7A8D8B;
margin-bottom: 20px;
`

const TagWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin:  30px;
  @media ${props => props.theme.screens.md} {
    justify-content: flex-start;
    margin: 0;
  }

`


interface IProps {
    date?: String;
    title: String;
    subline?: String;
    tags?: String[]
}
const PageHero = ({ title, date, subline, tags }: IProps) => {
    const { t } = useTranslation();
    const { background } = useStaticQuery(graphql`
    query {
      background: file(relativePath: {eq: "blog-hero.png"}) {
        childImageSharp {
          gatsbyImageData(
            width: 1200
            placeholder: NONE
            formats: [AUTO, WEBP, AVIF]
            quality: 90
          )
        }
      }
    }
  `);

    const ContentComponent = () => (
        <Container>
            <Content>
                {date && <DateSpan>{date}</DateSpan>}
                <Heading>{t(title.toString())}</Heading>
                {subline &&
                    <Text>{t(subline.toString())}</Text>
                }
                <TagWrapper>{tags && tags.length > 0 && tags.map(tag => tag && <Tag>{tag}</Tag>)}</TagWrapper>
            </Content>
        </Container>
    );

    return (
        <GatsbyBackgroundPageHero image={background}>
            <ContentComponent />
        </GatsbyBackgroundPageHero>
    );
};

export default PageHero;
