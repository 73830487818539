import React from 'react';
import tw, { styled } from 'twin.macro';
import data from '../../../config/site_data.json';
import { Container, Section } from '../../Grid';
import { Heading } from '../../Typography';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Glide from 'react-glidejs';
import SVGIcon from '../../SVGIcon';
import { v4 as uuidv4 } from 'uuid';

const StyledHeading = styled(Heading)`
  ${tw`text-extra`}
  color: #224B45 !important;

  @media ${props => props.theme.screens.lg} {
    font-size: 30px;
    line-height: 127%;
  }
`;

const Wrapper = styled(Section)`
  margin-top: 200px;
`;

const ItemList = styled.div`
  ${tw`hidden md:grid grid-cols-2 gap-4 mt-12 xl:grid-cols-4`}
`;

const SliderContainer = styled.div`
  ${tw`pl-4 mt-6 container mx-auto md:hidden`}
`;

const Item = styled.div`
  ${tw`flex items-center`}

  max-width: 267px;
  border-radius: 16px;
  background: #f6f7f6;
  padding: 20px;

  svg {
    color: #224B45 !important;
    ${tw`mr-6`}
  }

  @media ${props => props.theme.screens.md} {
    max-width: 100%;
    padding: 20px 30px;
    border-radius: 24px;
  }
`;

const Title = styled.h4`
  ${tw`text-extra font-medium`}
  color: #224B45 !important;
  
  @media ${props => props.theme.screens.md} {
    font-size: 21px;
  }
`;

const CleaningTypes = () => {
  const { t } = useTranslation();

  const { heading, items } = data.office_cleaning.cleaning_types;
  console.log(t(heading))
  return (
    <Wrapper>
      <Container>
        <StyledHeading>{t(heading)}</StyledHeading>
        <StyledHeading>{t("different_types_of_cleaning_2")}</StyledHeading>
        <ItemList>
          {items.map((item: any) => (
            <Item key={uuidv4()}>
              <SVGIcon icon={item.icon} />
              <Title>{t(item.title)}</Title>
            </Item>
          ))}
        </ItemList>
      </Container>
      <SliderContainer>
        <Glide
          hideArrows
          type="slider"
          perView={1.5}
          startAt={0}
          focusAt={0}
          breakpoints={{ 480: { perView: 1.2 } }}
        >
          {items.map((item: any) => (
            <Item key={uuidv4()}>
              <SVGIcon icon={item.icon} />
              <Title>{t(item.title)}</Title>
            </Item>
          ))}
        </Glide>
      </SliderContainer>
    </Wrapper>
  );
};

export default CleaningTypes;
