import * as React from 'react';
import { graphql, navigate } from 'gatsby';
import { styled } from 'twin.macro';
import { useI18next } from 'gatsby-plugin-react-i18next';

import Layout from '../Layout';
import SEO from '../Seo';
import { Container, Section } from '../Grid';
import PageHero from './PageHero'
import ArticleItem from './ArticleItem';
import BookingBanner from '../Page/BookingBanner';

import MDXLayout, { MDXContent } from '../../shared/MDXLayout';

const Article = styled.article`
  font-weight: 500 !important;
  margin-bottom: 60px;
`;

const Nav = styled.nav`
  margin-top: 60px;
  font-size: 18px;
  color: ${props => props.theme.colors.primary};
`;

const Heading = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  font-weight: 700;
  font-size: 40px;
  line-height: 127%;
  color: #224B45;
`;

const Box = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 50px;
  align-items: center;
`


const BlogPostTemplate = ({
  data,
  location,
}) => {
  if (typeof window === 'undefined') return null;
  const { previous, next, allMdx } = data

  const previousGroup = previous.group[0] ?? []
  const nextGroup = next.group[0] ?? []
  const previousNode = previousGroup.nodes ?? []
  const nextNode = nextGroup?.nodes ?? []
  const { language, t } = useI18next();
  const { group } = allMdx
  const { nodes } = group[0]
  const post = nodes.filter((node) => {
    return node.frontmatter.language === language
  })[0]
  const previousPost = previousNode.filter((node) => {
    return node.frontmatter.language === language
  })[0]
  const nextPost = nextNode.filter((node) => {
    return node.frontmatter.language === language
  })[0]
  if (!post) {
    navigate('/blog')
    return null
  }
 
  return (
    <Layout>
      <PageHero title={post.frontmatter.title} date={post.frontmatter.date} tags={post.frontmatter.tags} />
        <Section>
          <Article itemScope itemType="http://schema.org/Article">
            
            <MDXLayout>
              <MDXContent img={post.frontmatter.placeholderImg}>
              {post.body}
              </MDXContent>
            </MDXLayout>
          </Article>
          <hr />

<Container>
          <Box>
            <Heading>{t('blog_post_other_articles')}</Heading>
            <span onClick={() => {
              navigate('/blog')
            }} style={{ fontSize: 21, color: "#224B45", cursor: "pointer" }}>{t('blog_post_see_all')}</span>
          </Box>
          <Nav>
            <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
              }}
            >
              {previousPost && (
                <ArticleItem post={previousPost} />
              )}
              {nextPost && (
                <ArticleItem post={nextPost} />

              )}
            </ul>
          </Nav>
          <BookingBanner
            filename="home-bookingbanner.jpg"
            heading={`save_time_for_happy_things`}
            color="white"
          />
          </Container>
        </Section>
    </Layout>
  );
};

export const Head = ({ data: { allMdx } }) => {
  const { group } = allMdx
  const { nodes } = group[0]
  const post = nodes[0]
  return (
    <SEO
      title={post.frontmatter.title}
      description={post.frontmatter.description || post.excerpt}
    />
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $previousPostId: String
    $nextPostId: String
    $name: String
    $language: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    previous: allMdx(
      filter: {frontmatter: {name: {eq: $previousPostId}}}
    ) {
      group(field: frontmatter___name) {
        nodes {
          excerpt
          frontmatter {
            date
            title
            description
            slug
            language
            tags
            coverImg {
              childImageSharp {
                gatsbyImageData
              }
            }
            placeholderImg {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    next: allMdx(
      filter: {frontmatter: {name: {eq: $nextPostId}}}
    ) {
      group(field: frontmatter___name) {
        nodes {
          excerpt
          frontmatter {
            date
            title
            description
            slug
            language
            tags
            coverImg {
              childImageSharp {
                gatsbyImageData
              }
            }
            placeholderImg {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    allMdx(
      filter: {frontmatter: {name: {eq: $name}}}
    ) {
      group(field: frontmatter___name) {
        nodes {
          frontmatter {
            date
            description
            language
            name
            slug
            title
            tags
            placeholderImg {
              childrenImageSharp {
                gatsbyImageData
              }
            }
          }
          body
          excerpt(pruneLength: 160)
        }
      }
    }
    locales: allLocale(
      filter: {language: {eq: $language}, ns: {in: ["common", "not_found"]}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
