import React, {useContext} from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import StarRating from 'react-star-ratings';

import {Context} from '../../../state/store';
import tw, {styled} from 'twin.macro';
import data from '../../../config/site_data.json';
import {Container} from '../../Grid';
import {Button} from '../../Button';
import SVGIcon from '../../SVGIcon';

import Offers from './Offers';
import BookNowController from '../../BookNowController';

import GatbsyBackgroundImage from '../../../shared/GatsbyBackgroundImage';

const HeroContainer = styled(Container)`
  ${tw`relative`}

  padding-top: 23px;
  @media ${props => props.theme.screens.md} {
    padding-top: 57px;
  }

  @media ${props => props.theme.screens.xl} {
    max-width: 1188px;
  }
`;

const Heading = styled.h1`
  ${tw`text-secondary`}

  margin-top: 25px;
  font-size: 22px;
  line-height: 123%;
  max-width: 272px;
  margin-bottom: 27px;

  span {
    ${tw`inline-flex items-center text-primary`}
  }

  picture {
    width: 20px;
    height: 27px;
  }

  @media ${props => props.theme.screens.md} {
    font-size: 49px;
    line-height: 123%;
    letter-spacing: -0.01em;
    max-width: 555px;
    margin-bottom: 36px;

    picture {
      width: 45px;
      height: 50px;
    }
  }

  @media ${props => props.theme.screens.lg} {
    font-size: 54px;
    line-height: 122.5%;
  }
`;

const CTAContainer = styled.div`
  ${tw`flex flex-col items-start md:flex-row md:items-center md:flex-wrap md:my-8`}
`;

const HeroButton = styled(Button)`
  height: 42px;
  margin-bottom: 21px;

  @media ${props => props.theme.screens.md} {
    height: 68px;
    font-size: 21px;
    line-height: 139.02%;
    margin-bottom: 28px;
    padding-left: 32px;
    padding-right: 32px;
  }

  @media ${props => props.theme.screens.lg} {
    margin-bottom: 0;
  }
`;

const RatingWrapper = styled.div`
  ${tw`flex items-center`}

  // start styling
  .star-ratings {
    display: flex !important;
  }

  .star-container {
    width: 15px;
    height: 15px;
    padding: 0 !important;

    &:not(:last-child) {
      margin-right: 2px;
    }

    svg {
      width: 100% !important;
      height: 100% !important;
    }
  }

  @media ${props => props.theme.screens.md} {
    flex-basis: 100%;

    .star-container {
      width: 22px;
      height: 22px;

      &:not(:last-child) {
        margin-right: 3px;
      }
    }
  }

  @media ${props => props.theme.screens.lg} {
    flex-basis: 227px;
    margin-left: 26px;
  }
`;

const GoogleSvg = styled.div`
  flex-basis: 57px;
  width: 57px;
  margin-left: 10px;

  @media ${props => props.theme.screens.md} {
    flex-basis: 86px;
    width: 86px;
    margin-left: 15px;
  }
`;

const NotifyContainer = styled.div`
  ${tw`flex items-center justify-center mb-4 `}
`;

const NotifyText = styled.span`
  ${tw`uppercase text-300 ml-4`}

  font-size: 11px;
  line-height: 139.02%;
  letter-spacing: 0.075em;

  @media ${props => props.theme.screens.md} {
    font-size: 13px;
  }

  @media ${props => props.theme.screens.lg} {
    ${tw`text-secondary`}
    font-size: 14px;
  }
`;

const NotifyWrapper = styled.div`
  ${tw`flex items-center py-2 px-4 cursor-pointer hover:opacity-80 transition-opacity`}
  border-radius: 16px;
  background: #f6f7f6;
`;

const Hero: React.FC<{offers: any}> = ({offers}) => {
  const {t} = useTranslation();
  const [_, dispatch] = useContext(Context);
  const {desktop} = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: {eq: "landing-background.jpg"}) {
          childImageSharp {
            gatsbyImageData(
              width: 1200
              quality: 100
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `,
  );

  const {heading, heading_accent, button_text, notify_text} =
    data.homepage.hero;

  return (
    <GatbsyBackgroundImage
      image={desktop.childImageSharp}
      alt="Hero image"
      wrapperStyle={{padding: '0 0 20px 0'}}
      useNormalImgTag={true}
    >
      <HeroContainer>
        <Heading>
          {t(heading)}{' '}
          <span>
            {t(heading_accent)}
            <picture>
              <SVGIcon icon="hero-leaves" />
            </picture>
          </span>
        </Heading>
        <CTAContainer>
          <BookNowController>
            <HeroButton>{t(button_text)}</HeroButton>
          </BookNowController>
          <RatingWrapper>
            <StarRating
              rating={5}
              starRatedColor="#F5BA2D"
              numberOfStars={5}
              starDimension={'25px'}
              name="rating"
            />
            <GoogleSvg>
              <SVGIcon icon="hero-google" />
            </GoogleSvg>
          </RatingWrapper>
        </CTAContainer>
        <Offers offers={offers} />
      </HeroContainer>
      <HeroContainer>
        <NotifyContainer onClick={() => dispatch({type: 'TOGGLE_NOTIFY'})}>
          <NotifyWrapper>
            <SVGIcon icon="hero-bell" />
            <NotifyText>{t(notify_text)}</NotifyText>
          </NotifyWrapper>
        </NotifyContainer>
      </HeroContainer>
    </GatbsyBackgroundImage>
  );
};

export default Hero;
