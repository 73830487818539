import React, {useEffect, useState} from 'react';
import Layout from '../components/Layout';
import {graphql, navigate} from 'gatsby';
import tw, {styled} from 'twin.macro';
import {useI18next} from 'gatsby-plugin-react-i18next';
import data from '../config/site_data.json';
import {Container} from '../components/Grid';
import DynamicImage from '../components/DynamicImage';
import SEO from '../components/Seo';

const Wrapper = styled.section`
  padding-top: ${props => props.theme.headerHeight};
`;

const ErrorContainer = styled(Container)`
  ${tw`relative`}
`;

const Content = styled.div`
  ${tw`flex flex-col items-center justify-center mb-8 md:items-start md:relative`}

  @media ${props => props.theme.screens.md} {
    max-width: 431px;
    z-index: 2;
    margin-top: 60px;
    padding-bottom: 60px;
  }

  @media ${props => props.theme.screens.lg} {
    padding-bottom: 0;
    width: 60%;
    max-width: 700px;
  }
`;

const ImageContainer = styled.picture`
  ${tw`mt-8 md:absolute right-0 bottom-0`}
  width: 100%;
  max-width: 400px;

  @media ${props => props.theme.screens.md} {
    z-index: 1;
  }

  @media ${props => props.theme.screens.lg} {
    max-width: 40%;
  }
`;

const Heading = styled.h6`
  ${tw`mb-4 md:mb-8`}
  font-size: 40px;
  line-height: 122.5%;
  letter-spacing: -0.01em;
  color: #3e3e3e;

  @media ${props => props.theme.screens.md} {
    font-size: 60px;
  }
`;

const Text = styled.p`
  ${tw`font-medium text-center md:text-left`}
  font-size: 20px;
  line-height: 122.5%;
  letter-spacing: -0.01em;
  color: #3d3b3b;

  @media ${props => props.theme.screens.md} {
    font-size: 30px;
  }

  @media ${props => props.theme.screens.lg} {
    max-width: 510px;
  }
`;

const CounterWrapper = styled.div`
  ${tw`bg-white mt-8 md:mt-12 relative`}
  padding: 21px 17px;
  border-radius: 10px;
`;

const CounterText = styled.span`
  font-size: 19px;
  line-height: 122.5%;
  letter-spacing: -0.01em;
  color: #3d3b3b;

  @media ${props => props.theme.screens.md} {
    font-size: 22px;
  }
`;

const CounterNumbers = styled.span`
  ${tw`ml-8`}
  font-size: 19px;
  line-height: 122.5%;
  letter-spacing: -0.01em;
  color: #3d3b3b;

  @media ${props => props.theme.screens.md} {
    font-size: 22px;
  }
`;

const Counter = styled.span`
  position: absolute;
  right: 17px;
`;

const LastNumber = styled.span`
  opacity: 0;
  visibility: 0;
  pointer-events: none;
`;

const FourOFour = styled.span`
  ${tw`md:order-1 md:mt-14 lg:mt-0`}
  font-size: 60px;
  line-height: 122.5%;
  letter-spacing: -0.08em;
  color: #d5d4d4;

  @media ${props => props.theme.screens.md} {
    font-size: 170px;
  }

  @media ${props => props.theme.screens.lg} {
    font-size: 240px;
    align-self: flex-end;
  }
`;

const ErrorPage = () => {
  const {language, t} = useI18next();
  const {heading, text, back_home_text, back_home_time} = data.error_page;
  const [secondsToRedirect, setSecondsToRedirect] = useState(back_home_time);

  useEffect(() => {
    const timer = setInterval(() => {
      if (secondsToRedirect > 0) {
        setSecondsToRedirect(secondsToRedirect - 1);
      } else {
        navigate('/');
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [secondsToRedirect]);

  return (
    <Layout headerStyle="notFound">
      <SEO title={t('Oops!')} lang={language} noIndex={true} />

      <Wrapper>
        <ErrorContainer>
          <Content>
            <FourOFour>404</FourOFour>
            <Heading>{t(heading)}</Heading>
            <Text>{t(text)}</Text>
            <CounterWrapper>
              <CounterText>{t(back_home_text)}</CounterText>
              <CounterNumbers>
                00:00:0<LastNumber>0</LastNumber>
                <Counter>{secondsToRedirect}</Counter>
              </CounterNumbers>
            </CounterWrapper>
          </Content>
          <ImageContainer>
            <DynamicImage filename="error-picture.png" />
          </ImageContainer>
        </ErrorContainer>
      </Wrapper>
    </Layout>
  );
};

export default ErrorPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {language: {eq: $language}, ns: {in: ["common", "not_found"]}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
