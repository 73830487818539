import React, {useEffect, useState} from 'react';
import tw, {styled, theme} from 'twin.macro';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import SVGIcon from '../../SVGIcon';
import useWindowSize from '../../../hooks/useWindowSize';

interface IRegularListProps {
  title: string;
  items: [string];
}

const Wrapper = styled.div`
  ${tw`pb-4`}

  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @media ${props => props.theme.screens.lg} {
    border-bottom: none;
    &:not(:last-child) {
      margin-bottom: 56px;
    }
  }
`;

const Header = styled.div<{open: boolean}>`
  ${tw`flex items-center justify-between text-200 lg:mb-8`}

  color: ${props => (props.open ? props.theme.colors.primary : '')};
  margin-bottom: ${props => (props.open ? '16px' : 0)};

  @media ${props => props.theme.screens.lg} {
    ${tw`text-200`}
    svg {
      display: none;
    }
  }
`;

const Title = styled.h4`
  font-size: 19px;
  line-height: 123%;
  letter-spacing: -0.01em;

  @media ${props => props.theme.screens.lg} {
    font-size: 22px;
    line-height: 123%;
  }
`;

const List = styled.ul<{open: boolean}>`
  ${tw`w-full lg:grid lg:grid-cols-4 gap-y-8 gap-x-4`}
  overflow: hidden;
  max-height: ${props => (props.open ? 'max-content' : 0)};
`;

const Item = styled.li`
  ${tw`text-secondary flex mb-4 items-center`}

  &:last-child {
    margin: 0;
  }
`;

const Icon = styled.div`
  ${tw`flex justify-center items-center`}
  width: 35px;
  height: 35px;
  background: #eff7ee;
  border-radius: 100%;
`;

const Text = styled.p`
  ${tw`font-medium ml-4 lg:font-bold`}

  font-size: 15px;
  line-height: 24px;

  @media ${props => props.theme.screens.lg} {
    font-weight: bold;
    font-size: 19px;
    line-height: 140%;
    max-width: 209px;
  }
`;

const RegularList: React.FC<IRegularListProps> = ({title, items}) => {
  const {t} = useTranslation();

  const [open, setOpen] = useState(false);

  const size = useWindowSize();

  useEffect(() => {
    const breakpoint = theme('screens.lg').replace('px', '');

    if (size.width >= breakpoint) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [size]);

  return (
    <Wrapper>
      <Header open={open} onClick={() => setOpen(!open)}>
        <Title>{t(title)}</Title>
        <SVGIcon icon={`regular-chevron-${open ? 'up' : 'down'}`} />
      </Header>
      <List open={open}>
        {items.map((item: string) => (
          <Item key={item}>
            <Icon>
              <SVGIcon icon="regular-check" />
            </Icon>
            <Text>{t(item)}</Text>
          </Item>
        ))}
      </List>
    </Wrapper>
  );
};

export default RegularList;
