import React from 'react';
import tw, {styled} from 'twin.macro';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import data from '../../../config/site_data.json';
import {v4 as uuidv4} from 'uuid';

import {Container, Section} from '../../Grid';

import {Heading, Text} from '../../Typography';
import Glide from 'react-glidejs';

const WorkWrapper = styled(Section)``;

const Header = styled.div`
  ${tw`flex flex-col xl:flex-row xl:justify-between`}
`;

const Description = styled.p`
  ${tw`text-secondary font-medium mb-8`}
  font-size: 19px;
  line-height: 30px;

  @media ${props => props.theme.screens.xl} {
    flex-basis: 618px;
    margin-bottom: 0;
  }
`;

const StyledHeading = styled(Heading)`
  ${tw`text-200`}

  span {
    ${tw`block text-secondary`}
  }

  margin-bottom: 46px;

  @media ${props => props.theme.screens.md} {
    margin-bottom: 60px;
  }
`;

const List = styled.ul`
  ${tw`hidden lg:grid lg:grid-cols-1 lg:gap-x-10 lg:gap-y-10 xl:grid-cols-2`}
`;

const Item = styled.div`
  ${tw`flex flex-col md:flex-row md:items-center md:justify-between border border-100 transition-border-color`}

  max-width: 306px;
  width: 100%;
  padding: 19px 20px 25px 20px;

  border-radius: 24px;

  &:focus {
    outline: none;
    border: none;
  }

  &:hover {
    border-color: #32b66b;
    box-shadow: 9px 12px 26px rgba(0, 0, 0, 0.05);
  }

  @media ${props => props.theme.screens.md} {
    max-width: 100%;
    padding: 32px 47px;
  }
`;

const Title = styled.h3`
  ${tw`text-secondary mb-4 md:mr-8`}

  font-size: 26px;
  line-height: 122.5%;

  @media ${props => props.theme.screens.md} {
    font-size: 34px;
    line-height: 132.5%;
  }
`;

const StyledText = styled(Text)`
  ${tw`text-secondary font-medium`}

  @media ${props => props.theme.screens.lg} {
    max-width: 564px;
  }

  a {
    text-decoration: underline;
    color: #039259;
  }
`;

const SliderContainer = styled.div`
  ${tw`pl-4 container mx-auto lg:hidden`}
`;

const WorkWithUs = () => {
  const {t} = useTranslation();

  const {title1, title2, items, description} = data.homepage.work_with_us;

  return (
    <WorkWrapper>
      <Container>
        <Header>
          <StyledHeading>
            {t(`${title1}`)}
            <span> {t(`${title2}`)}</span>
          </StyledHeading>
          <Description>{t(description)}</Description>
        </Header>
        <List>
          {items.map((item: any) => {
            const {title, text} = item;
            return (
              <Item key={uuidv4()}>
                <Title>{t(`${title}`)}</Title>
                <StyledText dangerouslySetInnerHTML={{ __html: t(`${text}`)}} />
              </Item>
            );
          })}
        </List>
      </Container>
      <SliderContainer>
        <Glide
          hideArrows
          type="slider"
          perView={1.2}
          startAt={0}
          focusAt={0}
          gap={19}
        >
          {items.map((item: any) => {
            const {title, text} = item;
            return (
              <Item key={uuidv4()}>
                <Title>{t(`${title}`)}</Title>
                <StyledText>{t(`${text}`)}</StyledText>
              </Item>
            );
          })}
        </Glide>
      </SliderContainer>
    </WorkWrapper>
  );
};

export default WorkWithUs;
