import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import tw, { styled } from 'twin.macro';
import data from '../../../config/site_data.json';
import { Container, Section } from '../../Grid';
import { Heading } from '../../Typography';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Button } from '../../Button';

const Wrapper = styled(Section)`
  ${tw`relative`}
`;

const Content = styled.div`
  @media ${props => props.theme.screens.sm} {
    max-width: 100%;
    margin: 0 auto;
  }
  @media ${props => props.theme.screens.md} {
    max-width: 100%;
    margin: 0;
  }

  @media ${props => props.theme.screens.xl} {
    margin-left: 120px;
    width: 45%;
  }
`;

const StyledHeading = styled(Heading)`
  ${tw`text-extra`}
  color: #224B45;
  margin-bottom: 48px;

  @media ${props => props.theme.screens.md} {
    font-size: 30px;
    line-height: 127%;
  }
`;

const Text = styled.ol`
  display: flex;
  flex-wrap: wrap; /* Wrap the items onto multiple lines if needed */
  list-style-type: none; /* Remove default list styles */

  li {
    font-weight: 500;
    font-size: 21px;
    line-height: 24px;
    color: #224b45;
    list-style: none;
    display: flex;
    align-items: center; /* Vertically center the items */
    margin-bottom: 35px;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  a {
    color: #039259;
    text-decoration: underline;
  }

  @media ${props => props.theme.screens.lg} {
    font-size: 19px;
    line-height: 32px;
    margin-top: 32px;
    margin-bottom: 57px;
  }
`;

const StyledButton = styled(Button)`
  ${tw`w-full md:w-max`}
  border-radius: 10px;
  height: 46px;

  @media ${props => props.theme.screens.md} {
    height: 68px;
    font-size: 21px;
  }
`;

const Circle = styled.div`
  width: 52px;
  height: 52px;
  background: #eaf4ee;
  border-radius: 100%;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-size: 21px;
`;

const CustomContainer = styled(Container)`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
`;

const Image = styled.div`
  height: 475px;
  width: 100%;
  border-radius: 16px;
  object-fit: cover;
  background: url(${props => props.img}) no-repeat;

  @media ${props => props.theme.screens.lg} {
    width: 560px;
  }
`;

const TextItem = styled.div`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 32px;

  color: #224b45;
`;
const ListItem = ({ item }) => {
  return (
    <div className="flex flex-col lg:flex-row items-center mb-8">
      <div className="lg:w-1/2 w-full lg:w-2/5 lg:mr-20">
        <img src={item.image} alt={`Image ${item.id}`} className="w-full" />
      </div>
      <div className="lg:w-1/2">
        <div className="mb-4 lg:mb-0">
          <StyledHeading className="mt-12 lg:mt-0">
            {item.heading}
          </StyledHeading>
        </div>
        <ul className="list-disc">
          {item.subItems.map((subItem, index) => (
            <li key={index} className="flex items-center" style={{ paddingBottom: 20 }}>
              <div
                style={{
                  color: '#224B45',
                  backgroundColor: '#EAF4EE',
                  height: '52px',
                  width: '52px',
                }}
                className="flex-shrink-0 w-10 h-10 rounded-full bg-gray-400 text-white font-bold text-xl flex items-center justify-center mr-4"
              >
                {index + 1}
              </div>
              <TextItem className="flex-grow">{subItem}</TextItem>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const CleaningTypes = ({ button }: any) => {
  const { t } = useTranslation();

  const { heading, text, button_text } = data.office_cleaning.description;

  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: {eq: "office-cleaning-description.png"}) {
        childImageSharp {
          gatsbyImageData(
            width: 800
            quality: 90
            layout: CONSTRAINED
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  const processItems = t(`process_items`).split(';');

  return (
    <Wrapper>
      <CustomContainer>
        <ListItem
          item={{
            image: image.childImageSharp.gatsbyImageData.images.fallback.src,
            id: 'test',
            subItems: processItems,
            heading: t(heading),
          }}
        />
      </CustomContainer>
    </Wrapper>
  );
};

export default CleaningTypes;
