import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import tw, { styled } from 'twin.macro';
import data from '../../../config/site_data.json';
import { Container, Section } from '../../Grid';
import { Heading } from '../../Typography';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Button } from '../../Button';

const Wrapper = styled(Section)`
  ${tw`relative`}

  @media ${props => props.theme.screens.xl} {
    margin-top: 180px;
  }
`;

const Content = styled.div`
  @media ${props => props.theme.screens.sm} {
    max-width: 80%;
    margin: 0 auto;
  }
  @media ${props => props.theme.screens.md} {
    max-width: 80%;
    margin: 0;
  }

  @media ${props => props.theme.screens.xl} {
    max-width: 700px;
  }
`;

const StyledHeading = styled(Heading)`
  ${tw`text-secondary`}
  color: #224B45;
  font-size: 24px;

  @media ${props => props.theme.screens.md} {
    font-size: 30px;
  }
`;

const Text = styled.p`
  ${tw`my-6  text-secondary`}
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #224b45;
  margin-top: ${props => props.marginTop};

  a {
    color: #009259;
    text-decoration: underline;
  }

  @media ${props => props.theme.screens.lg} {
    font-size: 19px;
    line-height: 32px;
  }
`;

const StyledButton = styled(Button)`
  ${tw`w-full md:w-max`}
  border-radius: 10px;
  height: 46px;

  @media ${props => props.theme.screens.md} {
    height: 68px;
    font-size: 21px;
  }
`;

const StyledImgContainer = styled.div`
  padding: 15px;
  @media ${props => props.theme.screens.lg} {
    padding: 0px;
  }
`;

const StyledImageGroup = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  
  @media ${props => props.theme.screens.md} {
    flex-flow: row wrap;
  }

  @media ${props => props.theme.screens.lg} {
    flex-flow: column wrap;
    margin-right: 120px;
    width: 30%;

    img {
      width: 496px;
    }
  }
`

const CleaningTypes = ({ button }: any) => {
  const { t } = useTranslation();

  const { heading, text, text_2, text_3, button_text } =
    data.home_cleaning.description;

  const { homeCleaningFirst, homeCleaningSecond } = useStaticQuery(graphql`
    query {
      homeCleaningFirst: file(relativePath: {eq: "home-cleaning-desc-2.png"}) {
        childImageSharp {
          gatsbyImageData(
            width: 800
            quality: 90
            layout: CONSTRAINED
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      homeCleaningSecond: file(relativePath: {eq: "home-cleaning-desc-1.png"}) {
        childImageSharp {
          gatsbyImageData(
            width: 800
            quality: 90
            layout: CONSTRAINED
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  const firstImage = getImage(homeCleaningFirst);
  const secondImage = getImage(homeCleaningSecond);

  return (
    <Container>
      <Wrapper>
        <div className="flex flex-col lg:flex-row items-center mb-8">
          <StyledImageGroup className="items-start">
            <StyledImgContainer className="md:w-1/2 w-full lg:w-full lg:mb-8">
              <img
                src={firstImage?.images.fallback?.src}
                alt={`Image`}
                className="w-full"
              />
            </StyledImgContainer>
            <StyledImgContainer className="md:w-1/2 w-full lg:w-full">
              <img
                src={secondImage?.images.fallback?.src}
                alt={`Image`}
                className="w-full"
              />
            </StyledImgContainer>
          </StyledImageGroup>
          <div className="lg:w-1/2">
            <Container>
              <Content>
                <StyledHeading className="mt-10">{t(heading)}</StyledHeading>
                <Text marginTop="31px" dangerouslySetInnerHTML={{ __html: t(text) }} />
                <Text dangerouslySetInnerHTML={{ __html: t(text_2) }} />
                {t(text_3) && (
                  <Text dangerouslySetInnerHTML={{ __html: t(text_3) }} />
                )}
                {button && <StyledButton>{t(button_text)}</StyledButton>}
              </Content>
            </Container>
          </div>
        </div>
      </Wrapper>
    </Container>
  );
};

export default CleaningTypes;
