import React from 'react';
import tw, {styled} from 'twin.macro';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import data from '../../../config/site_data.json';
import {v4 as uuidv4} from 'uuid';

import {Container, Section} from '../../Grid';

import {Heading, Text} from '../../Typography';
import BookNowController from '../../BookNowController';

const BookingWrapper = styled(Section)``;

const StyledHeading = styled(Heading)`
  ${tw`text-secondary`}

  span {
    ${tw`block text-200`}
  }

  margin-bottom: 46px;

  @media ${props => props.theme.screens.md} {
    margin-bottom: 60px;
  }

  @media ${props => props.theme.screens.lg} {
    margin-left: 15px;
    margin-bottom: 120px;
  }
`;

const Title = styled.h3`
  ${tw`text-secondary`}

  font-size: 26px;
  margin-bottom: 8px;
  height: unset;
  line-height: 132.5%;

  @media ${props => props.theme.screens.md} {
    font-size: 32px;
    margin-bottom: 0px;
    height: 150px;
  }

  @media ${props => props.theme.screens.xl} {
    margin-bottom: 35px;
    height: 110px;
    margin-top: 20px;
  }
`;

const StyledText = styled(Text)`
  ${tw`font-medium text-secondary`}
  font-size: 15px;
  line-height: 24px;

  @media ${props => props.theme.screens.md} {
    font-size: 19px;
    line-height: 30px;
  }
`;

const Link = styled.span`
  ${tw`underline text-primary cursor-pointer`}
`;

const List = styled.ul`
  ${tw`grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4`};
  clear: both;
  list-style: none;
  flex-flow: column wrap;
  margin-top: 40px;
  padding: 0 15px;

  @media ${props => props.theme.screens.md} {
    padding: 0 30px;
  }
`;
const ListItem = styled.li`
  display: block;
  position: relative;
  counter-increment: inst;
  width: 100%;
  margin-bottom: 0;
  padding: 50px 0 0 50px;
  max-width: 281px;

  @media ${props => props.theme.screens.md} {
    margin-bottom: 35px;
    padding: 12px;
    margin-left: 0;
    margin-top: 20px;
    &:before {
      font-size: 190px !important;
      transform: translateX(-20%) translateY(-40%) !important;
      top: 30px !important;
    }
  }

  @media ${props => props.theme.screens.xl} {
    &:before {
      font-size: 290px !important;
      top: 0px !important;
    }
  }

  &:before {
    content: counter(inst);

    ${tw`text-primary opacity-10 z--1`}
    position: absolute;
    font-size: 160px;
    font-family: 'Quicksand';
    font-weight: 700;
    transform: auto;
    -webkit-text-stroke-width: 5px;
    -webkit-text-stroke-color: currentColor;

    padding-top: 0;
    padding-left: 2.25%;
    left: -7%;
    top: -10px;
    position: absolute;

    transition: all 0.2s ease-in-out;
  }
`;

interface IListItem {
  title: string;
  text: string;
  link: string | undefined;
}

const BookingProcess = () => {
  const {title1, title2, items} = data.homepage.booking_process;
  const {t} = useTranslation();

  return (
    <BookingWrapper>
      <Container>
        <StyledHeading>
          {t(`${title1}`)}
          <span> {t(`${title2}`)}</span>
        </StyledHeading>
        <List>
          {items.map((item: IListItem) => {
            const {title, text, link} = item;

            return (
              <ListItem key={uuidv4()}>
                <Title>
                  {t(`${title}`)}{' '}
                  {link && (
                    <BookNowController>
                      <Link>{t(`${link}`)}</Link>
                    </BookNowController>
                  )}
                </Title>
                <StyledText>{t(`${text}`)}</StyledText>
              </ListItem>
            );
          })}
        </List>
      </Container>
    </BookingWrapper>
  );
};

export default BookingProcess;
