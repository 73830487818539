module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Magnolia Uklid","short_name":"Magnolia","start_url":"/","background_color":"#FFFFFF","theme_color":"#32B66B","display":"standalone","icon":"src/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"506905192a814331e7d0bab0d2c21fa6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-remark-images","id":"580fd6f0-a0f4-5cb7-ac28-2bea65acb525","name":"gatsby-remark-images","version":"4.2.0","modulePath":"/opt/build/repo/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["cs","en"],"defaultLanguage":"cs","generateDefaultLanguagePage":true,"siteUrl":"https://www.magnolia-uklid.cz/","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-translate-urls/gatsby-browser.js'),
      options: {"plugins":[],"translations":{"en":{"urls.home-cleaning":"home-cleaning","urls.office-cleaning":"office-cleaning","urls.upholstery":"upholstery-and-carpet-cleaning","urls.cleaning-of-common-areas":"cleaning-of-common-areas","urls.window-washing":"window-washing","urls.post-construction-cleaning":"post-construction-cleaning","urls.prices":"prices-of-cleaning-services","urls.information-on-the-processing-of-personal-data":"information-on-the-processing-of-personal-data","urls.terms-and-conditions-of-service":"terms-and-conditions-of-service","urls.consent-to-the-processing-of-cookies":"consent-to-the-processing-of-cookies","urls.gift-vouchers":"gift-vouchers","urls.cleaning-after-reconstruction":"cleaning-after-reconstruction "},"cs":{"urls.home-cleaning":"uklid-domacnosti","urls.office-cleaning":"uklid-kancelari","urls.upholstery":"cisteni-calouneni-a-kobercu","urls.cleaning-of-common-areas":"uklid-spolecnych-prostor","urls.window-washing":"myti-oken","urls.post-construction-cleaning":"uklid-po-malovani-a-rekonstrukci","urls.prices":"cenik-uklidovych-praci","urls.information-on-the-processing-of-personal-data":"informace-o-zpracovani-osobnich-udaju","urls.terms-and-conditions-of-service":"vseobecne-obchodni-podminky","urls.consent-to-the-processing-of-cookies":"souhlas-se-zpracovanim-souboru-cookies","urls.gift-vouchers":"darkove-poukazky","urls.cleaning-after-reconstruction":"úklid-po-rekonstrukci "}},"defaultLocale":"cs","prefix":"urls.","forceSlashExclude":["/404.html","/cs/404.html","/en/404.html"]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
