import React, {useRef, useState} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {Container, Section} from '../../../Grid';
import tw, {styled} from 'twin.macro';
import {Heading} from '../../../Typography';
import Glide from 'react-glidejs';
import StarRating from 'react-star-ratings';

import data from '../../../../config/site_data.json';
import HeaderSlider from './HeaderSlider';
import {StaticImage, GatsbyImage, getImage} from 'gatsby-plugin-image';

const ReviewsWrapper = styled(Section)``;

const StyledHeading = styled(Heading)`
  ${tw`text-200`}

  max-width: 274px;

  span {
    ${tw`text-secondary`}
  }

  @media ${props => props.theme.screens.md} {
    max-width: 463px;
  }

  margin-bottom: 60px;

  @media ${props => props.theme.screens.lg} {
    margin-left: 57px;
  }
`;

const ReviewWrapper = styled.article`
  ${tw`flex flex-col items-center border border-primary relative w-full`}

  background-color: ${props => props.theme.colors.defaultBackground};

  &:before {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 25px solid ${props => props.theme.colors.primary};
    border-right: 25px solid transparent;
    border-top: 25px solid ${props => props.theme.colors.primary};
    border-bottom: 25px solid transparent;
    left: -1px;
    bottom: -22px;
    z-index: -1;
  }

  &:after {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 25px solid ${props => props.theme.colors.defaultBackground};
    border-right: 25px solid transparent;
    border-top: 25px solid ${props => props.theme.colors.defaultBackground};
    border-bottom: 25px solid transparent;
    left: 0px;
    bottom: -20px;
  }

  align-self: flex-start;
  margin-bottom: 30px;
  box-shadow: 9px 12px 26px rgba(0, 0, 0, 0.05);
  border-radius: 23px;
  padding: 26px 22px;

  max-width: 600px;

  @media ${props => props.theme.screens.md} {
    padding: 48px 48px;
    margin: 0 auto;
  }

  @media ${props => props.theme.screens.lg} {
    max-width: 800px;
    margin: 0;
  }
`;

const Title = styled.h4`
  ${tw`block text-secondary mb-4`}
  font-size: 24px;
  line-height: 158.5%;
  @media ${props => props.theme.screens.md} {
    font-size: 29px;
    line-height: 158.5%;
  }
`;

const Review = styled.p`
  ${tw`font-medium text-secondary text-center mt-4`}
  font-size: 16px;
  line-height: 158.5%;

  @media ${props => props.theme.screens.md} {
    font-size: 22px;
    line-height: 158.5%;
  }
`;

const SliderContainer = styled.div`
  ${tw`px-4 container mx-auto lg:hidden`}
`;

const ReviewsDesktop = styled.div`
  ${tw`hidden lg:flex`}
  @media ${props => props.theme.screens.lg} {
    margin-left: 57px;
  }
`;

const ReviewPerson = styled.div<{selected: boolean}>`
  ${tw`flex items-center w-full border border-100 transition-border-color transition-opacity cursor-pointer`}

  border-radius: 24px;
  padding: 12px 24px;

  border-color: ${props => (props.selected ? `` : `transparent`)};

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  &:first-child,
  &:last-child {
    position: relative;
    z-index: 6;
  }

  &:hover {
    opacity: 1;
    border-color: ${props => props.theme.colors[100]};
  }
`;

const PersonsWrapper = styled.div`
  ${tw`relative`}

  margin-right: 30px;

  &::before,
  &::after {
    ${tw`absolute  content`}
    height: 100px;
    width: calc(100% - 20px);
    z-index: 5;
    pointer-events: none;
  }

  &::before {
    top: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    );
    transform: matrix(1, 0, 0, -1, 0, 0);
  }

  &::after {
    height: 124px;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    );
  }
`;

const Persons = styled.div`
  ${tw`relative`}

  flex-basis: 431px;
  flex-shrink: 1;
  padding-right: 15px;

  max-height: 484px;
  overflow-y: scroll;

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #d7dddc;
    border-radius: 6px;
    margin-left: 10px;
    visibility: hidden;
  }

  /* Show the scrollbar on hover only */
  &:hover {
    &::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
`;

const ReviewFlex = styled.div`
  ${tw`flex justify-center`}
  /* flex-grow: 1; */
  margin-left: 20px;
`;

const Name = styled.span`
  ${tw`text-secondary block`}
  font-size: 22px;
  line-height: 132.5%;
`;

const Position = styled.span`
  ${tw`text-200 font-medium`}
  font-size: 17px;
  line-height: 32px;
`;

const ImageWrapper = styled.div`
  width: 48px;
  height: 48px;
  overflow: hidden;
  border-radius: 100%;
  margin-right: 24px;
`;

interface HTMLElement {
  changeIndex: Function;
  go: Function;
}

const Reviews: React.FC<{reviews: any}> = ({reviews}) => {
  const {t} = useTranslation();
  const {heading1, heading2} = data.homepage.reviews;

  const items = reviews
    .map(({node}: any) => {
      return {...node, comment: node.comment.comment, icon: node.icon};
    })
    .reverse();

  const [selected, setSelected] = useState<{
    id: string;
    icon: any;
    comment: string;
    company: string;
    name: string;
    position: string;
    rating: number;
    title: string;
  }>(() => {
    return items[0];
  });

  const reviewsRef = useRef({
    slider: React.createRef<HTMLElement>(),
    header: React.createRef<HTMLElement>(),
  });

  const handleSliderButton = (event: any) => {
    reviewsRef!.current!.slider!.current!.go(
      event.target.dataset.slideDirection,
    );
    reviewsRef!.current!.header!.current!.changeIndex();
  };

  const handleSliderUpdate = () => {
    reviewsRef!.current!.header!.current!.changeIndex();
  };

  const handleSelectReview = (id: any) => {
    setSelected(items.find((item: any) => item.id === id));
  };

  return (
    <ReviewsWrapper>
      <Container>
        <StyledHeading>
          {t(heading1)}
          <span> {t(heading2)}</span>
        </StyledHeading>
        <ReviewsDesktop>
          <PersonsWrapper>
            <Persons>
              {items.map((item: any) => (
                <ReviewPerson
                  key={item.id}
                  onClick={() => handleSelectReview(item.id)}
                  selected={selected!.id == item.id}
                >
                  <ImageWrapper>
                    {item.icon ? (
                      <GatsbyImage image={getImage(item.icon)} />
                    ) : (
                      <StaticImage
                        src={'../../../../assets/fallback-avatar.png'}
                        alt="magnolia alternative icon"
                      />
                    )}
                  </ImageWrapper>
                  <div>
                    <Name>{item.name}</Name>
                    <Position>
                      {item.position && item.company
                        ? `${item.position}, ${item.company}`
                        : ''}
                    </Position>
                  </div>
                </ReviewPerson>
              ))}
            </Persons>
          </PersonsWrapper>
          <ReviewFlex>
            <ReviewWrapper>
              <Title>{t(selected!.title)}</Title>
              <StarRating
                rating={selected!.rating}
                starRatedColor="#F5BA2D"
                numberOfStars={5}
                starDimension="25px "
                name="rating"
              />
              <Review>{t(selected!.comment)}</Review>
            </ReviewWrapper>
          </ReviewFlex>
        </ReviewsDesktop>

        <HeaderSlider
          ref={reviewsRef.current.header}
          items={items}
          handleSliderButton={handleSliderButton}
          sliderRef={reviewsRef.current.slider}
        />
      </Container>
      <SliderContainer>
        <Glide
          hideArrows
          ref={reviewsRef.current.slider}
          type="carousel"
          perView={1}
          startAt={0}
          focusAt={0}
          rewind={false}
          onSwipeEnd={handleSliderUpdate}
        >
          {items.map((item: any) => (
            <ReviewWrapper key={item.id}>
              <Title>{t(item.title)}</Title>
              <StarRating
                rating={item.rating}
                starRatedColor="#F5BA2D"
                numberOfStars={5}
                starDimension="25px "
                name="rating"
              />
              <Review>{t(item.comment)}</Review>
            </ReviewWrapper>
          ))}
        </Glide>
      </SliderContainer>
    </ReviewsWrapper>
  );
};

export default Reviews;
