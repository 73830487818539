import React from 'react'
import tw, { styled } from 'twin.macro';
import { Container } from '../Grid';

const Wrapper = styled.div`
    ${tw`flex-row`};
    margin-bottom: 50px;
`

const Content = styled.div`
    ${tw`flex-col`}
    height: 100%;
    padding: 70px 40px;
    width: 100%;
    padding-top: 0;

    h1 {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 127%;
        color: #224B45;
        margin-bottom: 30px;
        text-align: center;
        margin-bottom: 43px;

        @media ${props => props.theme.screens.md} {
            font-size: 40px;
          }
    }
    h2 {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 32px;
        text-align: center;
        color: #224B45;
        margin-bottom: 62px;
    }
    p {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 32px;
        color: #224B45;
    }

    ol {
        list-style: numbers;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        li {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 32px;
        color: #224B45;
        margin-bottom: 20px;
        }
    }
`
const List = ({ title, subline, underline, list }) => {
    const copy = [...list]
    const firstArray = list.length > 5 ? copy.splice(0, 5) : list
    const secondArray = list.length > 5 ? list.splice(-5) : []

    return (
        <Container>
            <Wrapper>
                <Content>
                    <h1>{title}</h1>
                    {subline &&
                        <h2>{subline}</h2>

                    }
                    <ol>
                        <div>
                            {firstArray.map(l => <li>{l}</li>)}
                        </div>
                        {secondArray?.length > 0 &&
                            <div>
                                {secondArray.map(l => <li>{l}</li>)}
                            </div>
                        }
                    </ol>
                    {underline && 
                        <h2 style={{ marginTop: 50 }}>{underline}</h2>
                    }
                </Content>
            </Wrapper>
        </Container>
    )
}

export default List
